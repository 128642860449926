import { Cubit } from "blac";

interface QuestionnairePreviewState {
  openViews: string[];
  key: number;
}

export default class QuestionnairePreviewCubit extends Cubit<QuestionnairePreviewState> {
  constructor() {
    super({
      openViews: ["steps"],
      key: 0
    });
  }

  closeView = (view: string): void =>
    this.emit({
      ...this.state,
      openViews: this.state.openViews.filter((v) => v !== view),
      key: this.state.key + 1
    });

  openView = (view: string): void =>
    this.emit({
      ...this.state,
      openViews: [...this.state.openViews, view],
      key: this.state.key + 1
    });

  toggleView = (view: string): void => {
    const index = this.state.openViews.indexOf(view);
    if (index === -1) {
      this.openView(view);
    } else {
      this.closeView(view);
    }
  };

  isViewOpen = (view: string): boolean =>
    this.state.openViews.indexOf(view) !== -1;
}
